import axios from "../../../services/Api";

export default {
  computed: {
    endingBalanceStore() {
      return this.points.points.points - this.product.value;
    },
    endingBalanceHomeDelivery() {
      if ((this.homeDelivery = true)) {
        return (
          this.points.points.points -
          (this.product.value + this.settings.shipping_price)
        );
      }
    },
    totalBalance() {
      return this.product.value + this.settings.shipping_price;
    },
  },
  methods: {
    // Muestra el Dialog de Recogida en tienda y oculta otras opciones
    showStoreAddress() {
      this.dialogPickUpStoreOptions = true;
      this.optionChosenHomeDelivery = false;
    },

    // Muestra el Dialog de Envío a Domicilio y oculta otras opciones
    showHomeDeliveryAddress() {
      this.dialogHomeDeliveryOptions = true;
      this.optionChosenStore = false;
    },

    // Muestra la dirección de Envío a Domicilio escogida
    chooseHomeDeliveryAddress() {
      this.dialogHomeDeliveryOptions = false;
      this.optionChosenStore = false;
      this.optionChosenHomeDelivery = true;
    },

    // Emit de recogida en tienda
    displayStoreOption(chosenOption) {
      this.chosenOption = chosenOption;
      this.optionChosenStore = true;
    },

    // Emit de envío a domicilio
    displayHomeDeliveryOption(infoProfile) {
      this.shipping_address_aux = infoProfile;
      this.optionChosenHomeDelivery = true;
    },

    // Emit que transforma la Dirección por defecto en la nueva Dirección. El comentario tiene que se mayor que 5.
    displayAddress(addressCopy) {
      this.shipping_address_aux = addressCopy;
    },

    // Cuando escoges una opción y das click al botón
    chooseOption() {
      if (this.optionChosenHomeDelivery == true) {
        if (this.shipping_address_aux != null) {
          this.buttonBlueExchange = false;
          this.buttonOrangeExchange = true;
          this.titleHomeDelivery = true;
          this.pickUpStore = false;
          this.homeDelivery = false;
          document.getElementById("buttonBuyProduct").style.zIndex = "1";
        } else {
          this.alertPleaseChooseAddress = true;
        }
      } else if (this.optionChosenStore == true) {
        this.buttonBlueExchange = false;
        this.buttonOrangeExchange = true;
        this.titlePickUpStore = true;
        this.pickUpStore = false;
        this.homeDelivery = false;
        document.getElementById("buttonBuyProduct").style.zIndex = "1";
      } else {
        this.alertPleaseChooseOption = true;
      }
    },

    sendOrder() {
      this.loadingOrder = true;
      setTimeout(() => {
        this.sendOrderToDB();
      }, 3000);
    },

    // Realiza una order a DB.
    // 1) Condición para hacer la Order. Necesita tener mínimo 5 de length
    // 2) Append de valores a tablas de DB
    // 3) Post a la DB

    async sendOrderToDB() {
      // if (this.shipping_address_aux == null || this.chosenOption.address === null) {
      //   this.loading = false;
      //   return (this.alertPleaseChooseAddress = true);
      // }

      const body = new FormData();
      body.append(
        "shipping_address",
        this.shipping_address_aux || this.chosenOption.address
      );
      body.append(
        "order_lines[]",
        JSON.stringify({
          product_id: this.product.id,
          quantity: 1,
          value: this.product.value,
        })
      );
      body.append(
        "shipping_price",
        this.radioChosen == "Envío a domicilio"
          ? this.settings.shipping_price
          : 0
      );
      body.append("home_delivery", 1);

      await axios
        .post("front-catalog/orders", body)
        .then(() => {
          this.loadingOrder = false;
          document.getElementById("buttonBuyProduct").classList.add("disabled");
        })
        .catch((error) => {
          this.loadingOrder = false;
          console.log(error);
          this.orderFail = true;
        })
        .finally(() => {
          this.OrderDone = true;
        });
    },
  },
};
